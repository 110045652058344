import React, { Fragment, useState } from 'react'
import BurgerMenu from 'react-burger-menu'
import MenuWrap from './MenuWrap'
import MenuItems from './MenuItems'
import About from './pages/About'
import Header from './pages/Header'
import Landing from './pages/Landing'
import { makeStyles } from '@material-ui/core/styles'
import Releases from './pages/Releases'
import Search from './pages/Search'
import Contact from './pages/Contact'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

const Hamburger = (props) => {
  const [currentMenu, setCurrentMenu] = useState('slide')
  const [side, setSide] = useState('left')
  const [isOpen, setIsOpen] = useState(false)

  const useStyles = makeStyles({
    spacer: {
      marginTop: '0px',
      backgroundColor: 'LightGrey',
      height: '100%',
    },
    myBurger: {
      top: '70px',
    },
    bodyStyle: {
      marginTop: '14px',
      paddingLeft: '24px',
      marginBottom: '4px',
      marginLeft: '104px',
      marginRight: '104px',
    },
  })

  const menus = {
    slide: { buttonText: 'Slide', items: 1 },
    stack: { buttonText: 'Stack', items: 1 },
    elastic: { buttonText: 'Elastic', items: 1 },
    bubble: { buttonText: 'Bubble', items: 1 },
    push: { buttonText: 'Push', items: 1 },
    pushRotate: { buttonText: 'Push Rotate', items: 2 },
    scaleDown: { buttonText: 'Scale Down', items: 2 },
    scaleRotate: { buttonText: 'Scale Rotate', items: 2 },
    fallDown: { buttonText: 'Fall Down', items: 2 },
    reveal: { buttonText: 'Reveal', items: 1 },
  }

  const menuvals = [
    [
      //      { mname: 'Settings', icon: 'fa-envelope-o', path: 'Settings' },
      { mname: 'Home', icon: 'fa-archive', path: '' },
      { mname: 'Releases', icon: 'fa-archive', path: 'Releases' },
      { mname: 'Search', icon: 'fa-search', path: 'Search' },
      { mname: 'About', icon: 'fa-comment-o', path: 'About' },
      { mname: 'Contact', icon: 'fa-envelope-o', path: 'Contact' },
    ],
    [
      { mname: 'Sidebar', icon: 'fa-inbox fa-2x', path: '#' },
      { mname: 'Data Management', icon: 'fa-database', path: '#' },
      { mname: 'Location', icon: 'fa-map-marker', path: '#' },
      { mname: 'Study', icon: 'fa-mortar-board', path: '#' },
      { mname: 'Collections', icon: 'fa-picture-o', path: '#' },
      { mname: 'Credits', icon: 'fa-money', path: '#' },
    ],
  ]

  const toggleMenu = () => {
    console.log('changing isOpen from ' + isOpen + ' to ' + !isOpen)
    setIsOpen(!isOpen)
  }

  const onStateChange = (state) => {
    console.log('updating state to ' + state.isOpen)
    setIsOpen(state.isOpen)
  }

  const classes = useStyles()

  const getMenu = () => {
    const Menu = BurgerMenu[currentMenu]

    const items = (
      <MenuItems
        menus={menus}
        currentMenu={currentMenu}
        menuVals={menuvals}
        menuState={toggleMenu}
      />
    )

    return (
      <>
        <MenuWrap children={props.children} wait={20} side={side}>
          <Menu
            isOpen={isOpen}
            onStateChange={onStateChange}
            burgerButtonClassName={classes.myBurger}
            id={currentMenu}
            pageWrapId={'page-wrap'}
            outerContainerId={'outer-container'}
            right={side === 'right'}
          >
            {items}
          </Menu>
        </MenuWrap>
      </>
    )
  }

  return (
    <Fragment>
      <Router hashHistory>
        <div id="outer-container" className={classes.spacer}>
          <Header />
          {getMenu()}
          <main id="page-wrap">
            <Switch>
              <Route
                key={0}
                path={'/'}
                exact={true}
                children={() => <Landing />}
              />
              <Route
                key={1}
                path={'/Search'}
                exact={true}
                children={() => <Search />}
              />
              <Route
                key={3}
                path={'/releases'}
                exact={false}
                children={() => <Releases />}
              />
              <Route
                key={4}
                path={'/about'}
                exact={false}
                children={() => <About />}
              />
              <Route
                key={5}
                path={'/contact'}
                exact={false}
                children={() => <Contact />}
              />
              <Route
                key={6}
                path={'*'}
                exact={false}
                children={() => <Landing />}
              />
            </Switch>
          </main>
        </div>
      </Router>
    </Fragment>
  )
}

export default Hamburger
