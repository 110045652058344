import { useForm } from 'react-hook-form'
import axios from 'axios'
import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { baseStyles } from './Styles'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const classes = baseStyles()

  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast',
    })
  }

  const sendEmail = (name, email, subject, message) => {
    const path = 'addMsg'

    const outerData = {
      name: name,
      email: email,
      subject: subject,
      msg: message,
    }

    axios({
      method: 'post',
      url: `${path}`,
      data: outerData,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(
      (res) => {
        if (res.data.length !== 0) {
          console.log(res.data)
          toastifySuccess()
        } else {
          console.log('Empty data returned')
        }
      },
      (error) => {
        console.log('got ' + error)
      },
    )
  }

  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message } = data
    console.log(
      'sending ' + name + ', ' + email + ', ' + subject + ', ' + message,
    )
    sendEmail(name, email, subject, message)
  }

  return (
    <Grid
      container
      className={classes.buttonSpacer}
      spacing={1}
      justifyContent="center"
    >
      <Grid item>
        <Grid item className="ContactForm">
          <Grid item className="container">
            <Grid item className="row">
              <Grid item className="col-12 text-center">
                <Grid item className="contactForm">
                  <form
                    id="contact-form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                  >
                    {/* Row 1 of form */}
                    <Grid container item className="row formRow row-grid">
                      <Grid item className="col-6">
                        <input
                          type="text"
                          name="name"
                          {...register('name', {
                            required: {
                              value: true,
                              message: 'Please enter your name',
                            },
                            maxLength: {
                              value: 30,
                              message: 'Please use 30 characters or less',
                            },
                          })}
                          className="form-control formInput"
                          placeholder="Name"
                        ></input>
                        {errors.name && (
                          <span className="errorMessage">
                            {errors.name.message}
                          </span>
                        )}
                      </Grid>
                      <Grid item className="col-6">
                        <input
                          type="email"
                          name="email"
                          {...register('email', {
                            required: true,
                            pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                          className="form-control formInput"
                          placeholder="Email address"
                        ></input>
                        {errors.email && (
                          <span className="errorMessage">
                            Please enter a valid email address
                          </span>
                        )}
                      </Grid>
                    </Grid>
                    {/* Row 2 of form */}
                    <Grid container item className="row formRow row-grid">
                      <Grid item className="col">
                        <input
                          type="text"
                          name="subject"
                          {...register('subject', {
                            required: {
                              value: true,
                              message: 'Please enter a subject',
                            },
                            maxLength: {
                              value: 75,
                              message: 'Subject cannot exceed 75 characters',
                            },
                          })}
                          className="form-control formInput"
                          placeholder="Subject"
                        ></input>
                        {errors.subject && (
                          <span className="errorMessage">
                            {errors.subject.message}
                          </span>
                        )}
                      </Grid>
                    </Grid>
                    {/* Row 3 of form */}
                    <Grid container item className="row  row-grid">
                      <Grid item className="col">
                        <textarea
                          rows={3}
                          name="message"
                          {...register('message', {
                            required: true,
                          })}
                          className="form-control formInput"
                          placeholder="Message"
                        ></textarea>
                        {errors.message && (
                          <span className="errorMessage">
                            Please enter a message
                          </span>
                        )}
                      </Grid>
                    </Grid>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      className="submit-btn row-grid"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                </Grid>
                <ToastContainer />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Contact
