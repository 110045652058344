import { AppBar, Link, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import corpImage from '../corp-logo.png'
import Image from 'material-ui-image'
import React from 'react'

const Header = () => {
  const useStyles = makeStyles({
    rightToolbar: {
      marginLeft: 'auto',
    },
    avatar: {
      width: 53,
      height: 64,
      backgroundColor: 'transparent',
    },
    link: {
      color: '#898B8F',
      '&:hover': {
        color: '#898B8F',
        textDecoration: 'none',
      },
    },
  })

  const classes = useStyles()

  return (
    <header>
      <AppBar>
        <Toolbar>
          <div>
            <Image
              style={{ width: 53, height: 64, backgroundColor: 'transparent' }}
              src={corpImage}
            />
          </div>
          <section className={classes.rightToolbar}>
            <Typography variant="h4" color="secondary">
              <Link href="#" className={classes.link}>
                Rating Slave
              </Link>
            </Typography>
          </section>
        </Toolbar>
      </AppBar>
    </header>
  )
}

export default Header
