import React, { Fragment } from 'react'
import { Grid } from '@material-ui/core'
import { baseStyles } from './Styles'
import Typography from '@material-ui/core/Typography'

const About = () => {
  const classes = baseStyles()

  return (
    <Fragment>
      <Grid container className={classes.buttonSpacer}>
        <Grid item>
          <Typography variant="h3">ISCS, Inc.</Typography>
          <Typography variant="h4" className={classes.paragraphStyle}>
            Background
          </Typography>
          <Typography
            className={classes.bodyStyle}
            variant="body1"
            display="block"
            align="left"
          >
            After several years using Sun Workstations in medical imaging
            software in surgical and research environments, the founder of ISCS,
            Inc. shifted gears and transferred his expertise to the broker
            dealer industry to leverage these rising new hardware and software
            game changing paradigms. He specialized in scripting middleware
            based on open source tools to customize and rewrap commercial
            products for the financial industry. After Y2K he shifted gears
            again and began to use his self-taught knowledge of web application
            development as a full stack engineer building J2EE apps within the
            JSF framework. These large multitiered environments became more
            cumbersome to build with all the boilerplate associated with
            Enterprise Java.
          </Typography>
          <Typography
            className={classes.bodyStyle}
            variant="body1"
            display="block"
            align="left"
          >
            Seeking to simplify the framework he shifted to reactive frameworks
            such as Play which greatly accelerated development speed. Over time
            this entry led to development of web services more than server side
            HTML rendering as the front-end decoupled from the server-side
            allowing greater focus on newer more performant asynchronous back
            end persistence layer compared to the legacy RDBMS systems in
            traditional financial applications. With exposure to other
            industries, in particular those with large streaming requirements,
            this hastened the push into functional development, primarily web
            services. His skills were self-taught and applied in the field
            responding to new client demands. While not his primary focus, with
            the decoupling of front and back end roles, he had to acquire
            increasing familiarity with state of the art frameworks exemplified
            by social media platforms to add a face to these services.
          </Typography>
          <Typography variant="h4" className={classes.paragraphStyle}>
            Rating Slave
          </Typography>
          <Typography
            className={classes.bodyStyle}
            variant="body1"
            display="block"
            align="left"
          >
            This application combines attributes of functional web services,
            asynchronous nosql collections, with a cutting edge front end to
            serve media content based on IMDB rating within several contexts.
            With publicly available data, you can filter films spanning greater
            than a century of cinema by rating, type, and genre. In addition,
            you can associate your favorite media performers with their work
            with those attributes as well. Please feel free to share your
            thoughts, comments or questions if you wish to learn more on the
            contact page.
          </Typography>
          <Typography
            className={classes.paragraphStyle}
            variant="body1"
            display="block"
            align="center"
          >
            <small>&copy; Copyright 2021, ISCS, Inc</small>
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default About
