import React, { useEffect, useState } from 'react';

const MenuWrap = ({children, wait, side}) => {
    const [hidden, setHidden] = useState(false);

    const show = () => {
        setHidden(false);
    };

    const getStyle = () => {
        let style;

        if (hidden) {
            style = { display: 'none' };
        }   
        return style;
    };

    useEffect(() => {
        console.log('in use effect ' + wait);
        setHidden(true);

        setTimeout(() => {
            show();
        }, wait);
    }, [wait, children.props.right]);

    
return (
        <div style={getStyle()} className={side}>
        {children}
        </div>
    );
};

export default MenuWrap;