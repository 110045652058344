import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FormControl, Grid, MenuItem, Select, Slider } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import { baseStyles, relStyles } from './Styles'
import combineStyles from './combineStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import pkgJson from '../../package.json'

const Releases = () => {
  const pkgBaseUrl = pkgJson.proxy
  axios.defaults.baseUrl = pkgBaseUrl
  const currentYear = new Date().getFullYear()
  const [year, setYear] = useState(currentYear)

  const zeropad = (numvalue) => {
    let strValue = numvalue + ''
    const pad = strValue.length === 1 ? '0' : ''
    return pad + strValue
  }

  const [month, setMonth] = useState(zeropad(new Date().getMonth() + 1))
  const [movietype, setMovietype] = useState('rel')
  const [rating, setRating] = useState('7')
  const [reldata, setReldata] = useState('')
  const relsStartYear = 1997
  const newMoviesStartYear = 2014
  const topMoviesStartYear = 1990
  const goodKey = '::'

  const classes = combineStyles(baseStyles, relStyles)()

  const getStartYear = () => {
    return movietype === 'rel'
      ? relsStartYear
      : movietype === 'new'
      ? newMoviesStartYear
      : topMoviesStartYear
  }

  const markss = [
    { value: 0, label: <strong>0</strong> },
    { value: 2, label: '2' },
    { value: 4, label: '4' },
    { value: 6, label: '6' },
    { value: 8, label: '8' },
    { value: 10, label: <strong>10</strong> },
  ]

  const movie = [
    { name: 'Releases', value: 'rel' },
    { name: 'Top', value: 'top' },
    { name: 'New', value: 'new' },
  ]

  const movieOptions = () => {
    return movie.map((k, index) => (
      <MenuItem value={k.value}>{k.name}</MenuItem>
    ))
  }

  const months = [
    { name: 'January', value: '01' },
    { name: 'February', value: '02' },
    { name: 'March', value: '03' },
    { name: 'April', value: '04' },
    { name: 'May', value: '05' },
    { name: 'June', value: '06' },
    { name: 'July', value: '07' },
    { name: 'August', value: '08' },
    { name: 'September', value: '09' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' },
  ]

  useEffect(() => {
    console.log('in useEffect releases')
    const path = prepData(currentYear, month, rating)
    getData(path).then((res) => {
      processData(res.data)
    })
  }, [])

  const monthOptions = () => {
    return months.map((k, index) => (
      <MenuItem key={k.value} value={k.value}>
        {k.name}
      </MenuItem>
    ))
  }

  const processData = (data) => {
    if (Object.keys(data)[0] === goodKey) {
      console.log(data['::'])
      setReldata(data['::'])
    } else {
      console.log('Empty data returned')
      setReldata(null)
    }
  }

  const getData = (path) => {
    return axios.get(`${path}`)
  }

  const prepData = (yr, mon, rtng) => {
    const path =
      movietype === 'rel'
        ? `reldate/${yr}/${mon}/${rtng}`
        : `${movietype}/${yr}/${rtng}`
    return 'api/v1/' + path
  }

  const onSelectMovietype = (mtPicked) => {
    console.log('picked ' + mtPicked + (month != null && ' month ' + month))
    setMovietype(mtPicked)
  }

  const onSelectYear = (event, yrPicked) => {
    console.log('picked ' + yrPicked + (month != null && ' month ' + month))
    setYear(yrPicked)
  }

  const onSelectMonth = (monPicked) => {
    console.log('picked ' + (year != null && year) + ' month ' + monPicked)
    setMonth(monPicked)
  }

  const onChangeRating = (event, ratingPicked) => {
    console.log(
      'picked ' +
        (year != null && year) +
        (month != null && ' month ' + month) +
        ' rating ' +
        ratingPicked,
    )
    setRating(ratingPicked)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const canQuery =
      movietype === 'rel' ? month !== '' && year !== null : year !== null
    if (canQuery) {
      const path = prepData(year, month, rating)
      getData(path)
        .then((res) => {
          processData(res.data)
        })
        .catch((error) => {
          console.log(
            'fetch ' + year + ' ' + month + ' ' + rating + ' got ' + error,
          )
        })
    }
  }

  const valuetext = (value) => {
    return `${value}`
  }

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
      flex: 2.5,
      minWidth: 200,
      renderCell: (params) => (
        <a href={params.row.link} target={'_blank'}>
          {params.row.title}
        </a>
      ),
    },
    {
      field: 'rating',
      width: 130,
      headerName: 'Rating',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
    },
  ]

  return (
    <Grid container direction="row" className={classes.buttonSpacer}>
      <Grid container item xs={2} direction="column">
        <Button
          className={classes.select_input}
          size="small"
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Show Films
        </Button>
        <FormControl
          variant="outlined"
          size="small"
          className={classes.select_input}
        >
          <Select
            id="movietype-select"
            value={movietype}
            onChange={(e) => onSelectMovietype(e.target.value)}
          >
            <MenuItem value="" disabled>
              <em>Movie Type</em>
            </MenuItem>
            {movieOptions()}
          </Select>
        </FormControl>
        <div id="year-selector" className={classes.slider_input}>
          <Typography id="year-slider">Year</Typography>
          <Slider
            aria-labelledby="discrete-slider-custom"
            getAriaValueText={valuetext}
            defaultValue={currentYear}
            min={getStartYear()}
            max={currentYear}
            step={1}
            track={false}
            marks={markss}
            valueLabelDisplay="on"
            onChange={onSelectYear}
          />
        </div>
        {movietype === 'rel' && (
          <FormControl
            variant="outlined"
            size="small"
            className={classes.select_input}
          >
            <Select
              id="month-select"
              value={month}
              onChange={(e) => onSelectMonth(e.target.value)}
            >
              <MenuItem value="" disabled>
                <em>Month</em>
              </MenuItem>
              {monthOptions()}
            </Select>
          </FormControl>
        )}
        <div id="rating-selector" className={classes.imdb_slider}>
          <Typography id="rating-slider">IMDB Rating</Typography>
          <Slider
            aria-labelledby="discrete-slider-custom"
            getAriaValueText={valuetext}
            defaultValue={7}
            min={0}
            max={10}
            step={0.1}
            marks={markss}
            track="inverted"
            valueLabelDisplay="auto"
            onChange={onChangeRating}
          />
        </div>
      </Grid>
      <Grid container item xs={10}>
        <Grid item xs={12}>
          {reldata !== null && reldata.length > 0 && (
            <DataGrid
              rows={reldata}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
            />
          )}
          {reldata === null && (
            <Typography id="nodata-release">No Data</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Releases
