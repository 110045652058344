export default function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const ourStyles = styles.map((arg) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme)
      }
      // Objects need no change.
      return arg
    })

    return ourStyles.reduce((acc, val) => Object.assign(acc, val))
  }
}
