import React, { Fragment } from 'react'
import { Grid } from '@material-ui/core'
import { baseStyles } from './Styles'
import Typography from '@material-ui/core/Typography'

const Landing = () => {
  const classes = baseStyles()

  return (
    <Fragment>
      <Grid container className={classes.buttonSpacer}>
        <Grid item>
          <Typography
            className={classes.bodyStyle}
            variant="body1"
            display="block"
            align="left"
          >
            Rating Slave offers two ways for ranking movies by IMDB ratings. In
            addition, one can also search by content format, genre, release
            year, number of votes and maturity. If you know the name of an actor
            you can find all movies in which that person had a role. Just switch
            the Title to Actor. Default settings for both menu choices allow you
            to easily find your picks. Desktop usage preferred.
            <br />
            <br />
            Menu choices are:
            <ul>
              <li>
                <b>Releases</b> where recent releases, new or top movies can be
                fitered
              </li>
              <li>
                <b>Search</b> where individual titles or names of performers can
                be filtered
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Landing
