import { makeStyles } from '@material-ui/core/styles'

export const baseStyles = makeStyles({
  buttonSpacer: {
    minHeight: '90%',
    marginTop: '60px',
    marginBottom: '64px',
    backgroundColor: 'Linen',
  },
  bodyStyle: {
    marginTop: '14px',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginLeft: '104px',
    marginRight: '104px',
  },
  paragraphStyle: {
    marginTop: '14px',
    marginBottom: '14px',
  },
})

export const relStyles = makeStyles({
  select_input: {
    width: '160px',
    margin: '5px !important',
  },
  slider_input: {
    width: '160px',
    margin: '5px',
    paddingLeft: '7px',
    paddingRight: '7px',
    '& > span > span > span': {
      top: '-24px',
      marginLeft: '3px',
      fontSize: '0.55rem !important',
    },
    '& > span > span > span > span': {
      width: '24px',
      height: '24px',
    },
  },
  imdb_slider: {
    width: '160px',
    margin: '5px',
    paddingLeft: '7px',
    paddingRight: '7px',
    '& > span > span > span': {
      top: '-24px',
      marginLeft: '3px',
      fontSize: '0.55rem !important',
    },
    '& > span > span > span > span': {
      width: '24px',
      height: '24px',
    },
  },
  gridStyle: {},
})

export const searchStyles = makeStyles({
  react_autosuggest__input: {
    width: '160px',
    height: '30px',
    margin: '5px',
    padding: '10px 20px',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: '300',
    fontSize: '16px',
    border: '1px solid #aaa',
    borderRadius: '4px',
  },
  react_autosuggest__input__focused: {
    outline: 'none',
  },
  react_autosuggest__input__open: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
  },
  autosuggest_input: {
    width: '160px',
  },
})
