import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'

const MenuItems = ({ menus, currentMenu, menuVals, menuState }) => {
  const getItems = () => {
    let items

    switch (menus[currentMenu].items) {
      case 1:
        items = menuVals[0].map((menu, index) => (
          <NavLink
            onClick={menuState}
            key={index}
            to={`${menu.path}`}
            activeClassName={`fa fa-fw ${menu.icon}`}
          >
            <span>{menu.mname}</span>
          </NavLink>
        ))
        break
      case 2:
        items = menuVals[1].map((menu, index) => (
          <NavLink
            onClick={menuState}
            key={index}
            to={`${menu.path}`}
            activeClassName={`fa fa-fw ${menu.icon}`}
          >
            <span>{menu.mname}</span>
          </NavLink>
        ))
    }
    return items
  }

  return <Fragment>{getItems()}</Fragment>
}

export default MenuItems
