import React, { useEffect, useState } from 'react'
import Toggle from 'react-toggle'
import axios from 'axios'
import {
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'
import Typography from '@material-ui/core/Typography'
import { baseStyles, relStyles, searchStyles } from './Styles'
import combineStyles from './combineStyles'
import Button from '@material-ui/core/Button'
import pkgJson from '../../package.json'
import Autosuggest from 'react-autosuggest'
import { defaultTheme } from 'react-autosuggest/dist/theme'
import 'react-toggle/style.css'

const Search = () => {
  const pkgBaseUrl = pkgJson.proxy
  axios.defaults.baseUrl = pkgBaseUrl
  const [autotype, setAutotype] = useState('title')
  const [rating, setRating] = useState('6')
  const [votes, setVotes] = useState('50000')
  const [imdbdata, setImdbdata] = useState([])
  const [namesuggestions, setNamesuggestions] = useState([])
  const [titlesuggestions, setTitlesuggestions] = useState([])
  const apiver = 'api/v1/'
  const [selectedGenres, setSelectedGenres] = useState(['Action', 'Adventure'])
  const [name, setName] = useState('')
  const [value, setValue] = useState('')
  const [title, setTitle] = useState('')
  const [selectedTitleType, setSelectedTitleType] = useState(['movie'])
  const [adult, setAdult] = useState(false)
  const currentYear = new Date().getFullYear()
  const basicStartYear = 1895
  const defaultRoleYear = 1990

  const yearValues = [defaultRoleYear, currentYear]
  const [years, setYears] = useState(yearValues)

  const classes = combineStyles(baseStyles, relStyles, searchStyles)()

  const markss = [
    { value: 0, label: <strong>0</strong> },
    { value: 2, label: '2' },
    { value: 4, label: '4' },
    { value: 6, label: '6' },
    { value: 8, label: '8' },
    { value: 10, label: <strong>10</strong> },
  ]

  const genres = [
    { name: 'Action', value: 'Action' },
    { name: 'Adult', value: 'Adult' },
    { name: 'Adventure', value: 'Adventure' },
    { name: 'Animation', value: 'Animation' },
    { name: 'Biography', value: 'Biography' },
    { name: 'Comedy', value: 'Comedy' },
    { name: 'Crime', value: 'Crime' },
    { name: 'Documentary', value: 'Documentary' },
    { name: 'Drama', value: 'Drama' },
    { name: 'Family', value: 'Family' },
    { name: 'Fantasy', value: 'Fantasy' },
    { name: 'Film-Noir', value: 'Film Noir' },
    { name: 'Game-Show', value: 'Game Show' },
    { name: 'History', value: 'History' },
    { name: 'Horror', value: 'Horror' },
    { name: 'Music', value: 'Music' },
    { name: 'Musical', value: 'Musical' },
    { name: 'Mystery', value: 'Mystery' },
    { name: 'News', value: 'News' },
    { name: 'Reality-TV', value: 'Reality TV' },
    { name: 'Romance', value: 'Romance' },
    { name: 'Sci-Fi', value: 'Sci-Fi' },
    { name: 'Short', value: 'Short' },
    { name: 'Sport', value: 'Sport' },
    { name: 'Talk-Show', value: 'Talk Show' },
    { name: 'Thriller', value: 'Thriller' },
    { name: 'War', value: 'War' },
    { name: 'Western', value: 'Western' },
  ]

  const movie = [
    { name: 'movie', value: 'movie' },
    { name: 'short', value: 'short' },
    { name: 'tvEpisode', value: 'tvEpisode' },
    { name: 'tvMiniSeries', value: 'tvMiniSeries' },
    { name: 'tvMovie', value: 'tvMovie' },
    { name: 'tvSeries', value: 'tvSeries' },
    { name: 'tvShort', value: 'tvShort' },
    { name: 'tvSpecial', value: 'tvSpecial' },
    { name: 'video', value: 'video' },
    { name: 'videoGame', value: 'videoGame' },
  ]

  const roles = {
    default: {
      genre: ['Action', 'Adventure'],
      year: [1990, 2010],
      votes: 50000,
      titleType: ['movie'],
      isAdult: false,
    },
    gamer: {
      genre: ['Fantasy', 'Sci-Fi'],
      year: [2005, 2020],
      titleType: ['videoGame'],
    },
    oldies: {
      genre: ['Drama', 'Romance'],
      year: [1945, 1965],
      titleType: ['movie', 'tvMovie'],
      isAdult: false,
    },
  }

  const autotypes = [
    { name: 'Title', value: 'title' },
    { name: 'Actor', value: 'name' },
  ]

  useEffect(() => {
    console.log('in useEffect search')
    getData('title/6', roles['default']).then((res) => {
      processData(res.data)
    })
  }, [])

  const autoTypeOptions = () => {
    return autotypes.map((k, index) => (
      <MenuItem value={k.value}>{k.name}</MenuItem>
    ))
  }

  const movieTypeOptions = () => {
    return movie.map((k, index) => (
      <MenuItem key={k.name} value={k.value}>
        <Checkbox checked={selectedTitleType.indexOf(k.name) > -1} />
        <ListItemText primary={k.name} />
      </MenuItem>
    ))
  }

  const genreTypeOptions = () => {
    return genres.map((k, index) => (
      <MenuItem key={k.name} value={k.value}>
        <Checkbox checked={selectedGenres.indexOf(k.name) > -1} />
        <ListItemText primary={k.name} />
      </MenuItem>
    ))
  }

  const genBodyData = (t, titleType, yrs, genre, votez, adult) => {
    let reqData = {}
    if (t.length !== 0) reqData['query'] = t
    if (genre.length !== 0) reqData['genre'] = genre
    if (yrs.length !== 0) reqData['year'] = yrs
    if (titleType.length !== 0) reqData['titleType'] = titleType
    if (votez.length !== 0) reqData['votes'] = votez
    if (typeof adult !== 'undefined') reqData['isAdult'] = adult
    return reqData
  }

  const renameKey = (origKey, newKey, objList) => {
    const newList = objList.map((obj) => {
      obj[newKey] = obj[origKey]
      delete obj[origKey]
      return obj
    })
    return newList
  }

  const getData = (path, outerData) => {
    return axios.post(`${apiver + path}`, JSON.stringify(outerData))
  }

  const processData = (data) => {
    if (data.length !== 0) {
      console.log(data)
      setImdbdata(renameKey('_id', 'id', data))
    } else {
      console.log('Empty response')
      setImdbdata([])
    }
  }

  const prepData = (t, yr, rtng, votez, selTitleTypes, selGenres) => {
    const path = autotype === 'title' ? `/title/${rtng}` : `/name/${t}/${rtng}`
    const outerData = genBodyData(t, selTitleTypes, yr, selGenres, votez, adult)
    return [path, outerData]
  }

  const fetchAutoValue = (t) => {
    const path = autotype === 'title' ? `autotitle/${t}` : `autoname/${t}`
    axios.get(`${apiver + path}`).then((res) => {
      console.log('fetching ' + res.data.length + autotype)
      if (autotype === 'title') setTitlesuggestions(res.data)
      else setNamesuggestions(res.data)
    })
  }

  const onTitlesuggestionsFetchRequested = ({ value }) => {
    const inputLength = value.length
    return inputLength <= 2
      ? []
      : fetchAutoValue(escapeRegexCharacters(capitalize(value)))
  }

  const onNamesuggestionsFetchRequested = ({ value }) => {
    const inputLength = value.length
    return inputLength <= 2
      ? []
      : fetchAutoValue(escapeRegexCharacters(capitalize(value)))
  }

  const onTitlesuggestionsClearRequested = () => {
    setTitlesuggestions([])
  }

  const onNamesuggestionsClearRequested = () => {
    setNamesuggestions([])
  }

  const renderTitlesuggestion = (suggestion) => {
    return <span>{suggestion.primaryTitle}</span>
  }

  const renderNamesuggestion = (suggestion) => {
    return (
      <span>
        {suggestion.firstName} {suggestion.lastName}
      </span>
    )
  }

  const getTitlesuggestionValue = (suggestion) => {
    return suggestion.primaryTitle
  }

  const getNamesuggestionValue = (suggestion) => {
    return suggestion.firstName + ' ' + suggestion.lastName
  }

  const onTitleChange = (event, { newValue, method }) => {
    setValue(newValue)
    setTitle(newValue)
  }

  const onNameChange = (event, { newValue, method }) => {
    setValue(newValue)
    setName(newValue)
  }

  const titleInputProps = {
    placeholder: `Type ${autotype}`,
    value,
    onChange: onTitleChange,
  }

  const nameInputProps = {
    placeholder: `Type actor`,
    value,
    onChange: onNameChange,
  }

  const onSelectAdult = (mtPicked) => {
    console.log('adult picked ' + mtPicked)
    setAdult(mtPicked)
  }

  const onSelectAutotype = (autoPicked) => {
    console.log('auto picked ' + autoPicked)
    setAutotype(autoPicked)
    setValue('')
    setTitle('')
    setName('')
  }

  const onSelectedTitleType = (titleType) => {
    console.log('selectedTitletype picked ' + titleType)
    setSelectedTitleType(
      titleType[0] === 'Movie Type'
        ? titleType.slice(1)
        : titleType.length === 0
        ? ['Movie Type']
        : titleType,
    )
  }

  const onSelectedGenre = (genre) => {
    console.log('genre picked ' + genre)
    setSelectedGenres(
      genre[0] === 'Genre'
        ? genre.slice(1)
        : genre.length === 0
        ? ['Genre']
        : genre,
    )
  }

  const onSelectYear = (event, yrPicked) => {
    console.log('year picked ' + yrPicked)
    setYears(yrPicked)
  }

  /*   const onClickSort = useCallback(
    (model) => {
      const mfield =
        model.length !== 0 && 'field' in model[0]
          ? model[0]['field']
          : 'NOVALUE'
      console.log('setting column to ' + mfield)
      //setSortCol(mfield)
      setSortModel(model)
    },
    [imdbdata],
  ) */

  const onChangeRating = (event, ratingPicked) => {
    console.log('picked rating ' + ratingPicked)
    setRating(ratingPicked)
  }

  const onChangeVotes = (event, votesPicked) => {
    console.log('picked votes ' + votesPicked)
    setVotes(votesPicked)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const canQuery = autotype === 'title' ? years.length > 0 : years.length > 0
    const titleOrName = autotype === 'title' ? title : name
    const searchWords = titleOrName.split(' ')
    const capSearchWords =
      searchWords[0] === '' ? [''] : searchWords.map((word) => capitalize(word))
    const capSearchQuery = capSearchWords.join(' ')
    // Remove default first element from selectTitleType & selectedGenres
    if (canQuery) {
      const [path, outerData] = prepData(
        capSearchQuery,
        years,
        rating,
        votes,
        selectedTitleType[0] === 'Movie Type'
          ? selectedTitleType.slice(1)
          : selectedTitleType,
        selectedGenres[0] === 'Genre'
          ? selectedGenres.slice(1)
          : selectedGenres,
      )
      getData(path, outerData)
        .then((res) => {
          processData(res.data)
        })
        .catch((error) => {
          console.log('fetch ' + autotype + ' got ' + error)
        })
    }
  }

  const valuetext = (value) => {
    return `${value}`
  }

  const escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const capitalize = ([first, ...rest], lowerRest = false) =>
    first.toUpperCase() +
    (lowerRest ? rest.join('').toLowerCase() : rest.join(''))

  const getRating = (params) => {
    const retVal =
      typeof params.row.averageRating === 'object'
        ? 'N/A'
        : params.row.averageRating
    return retVal
  }

  const nameColumns = [
    {
      field: 'primaryTitle',
      headerName: 'Title',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
      flex: 2,
      minWidth: 195,
      renderCell: (params) => (
        <a href={'https://imdb.com/title/' + params.row.id}>
          {params.row.primaryTitle}
        </a>
      ),
    },

    {
      field: 'averageRating',
      width: 135,
      headerName: 'Rating',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
      valueGetter: getRating,
    },
    {
      field: 'numVotes',
      width: 125,
      headerName: 'Votes',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
    },
    {
      field: 'startYear',
      width: 115,
      headerName: 'Year',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
    },
    {
      field: 'genresList',
      headerName: 'Genre',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
      flex: 1,
      valueGetter: (params) => `${params.row.genresList.join(', ')}`,
    },
  ]

  const titleColumns = [
    {
      field: 'primaryTitle',
      headerName: 'Title',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
      flex: 2,
      minWidth: 195,
      renderCell: (params) => (
        <a href={'https://imdb.com/title/' + params.row.id} target={'_blank'}>
          {params.row.primaryTitle}
        </a>
      ),
    },
    {
      field: 'averageRating',
      width: 135,
      cellClassName: 'gridCell',
      headerName: 'Rating',
      headerClassName: 'colHeader',
      valueGetter: getRating,
    },
    {
      field: 'numVotes',
      width: 125,
      headerName: 'Votes',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
    },
    {
      field: 'startYear',
      width: 115,
      headerName: 'Year',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
    },
    {
      field: 'genresList',
      headerName: 'Genre',
      cellClassName: 'gridCell',
      headerClassName: 'colHeader',
      flex: 1,
      valueGetter: (params) => `${params.row.genresList.join(', ')}`,
    },
  ]

  return (
    <Grid container direction="row" className={classes.buttonSpacer}>
      <Grid container item xs={2} direction="column">
        <Button
          className={classes.select_input}
          size="small"
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Show Films
        </Button>
        <div id="rating-selector" className={classes.imdb_slider}>
          <Typography id="rating-slider">IMDB rating</Typography>
          <Slider
            aria-labelledby="discrete-slider-custom"
            getAriaValueText={valuetext}
            defaultValue={7}
            min={0}
            max={10}
            step={0.1}
            marks={markss}
            track="inverted"
            valueLabelDisplay="auto"
            onChange={onChangeRating}
          />
        </div>
        <FormControl
          variant="outlined"
          size="small"
          className={classes.select_input}
        >
          <Select
            id="autotype-select"
            value={autotype}
            onChange={(e) => onSelectAutotype(e.target.value)}
          >
            <MenuItem value="" disabled>
              <em>Search Type</em>
            </MenuItem>
            {autoTypeOptions()}
          </Select>
        </FormControl>
        {autotype === 'title' && (
          <div id="auto-selector" className={classes.autosuggest_input}>
            <Autosuggest
              theme={{
                ...defaultTheme,
                input: classes.react_autosuggest__input,
                inputOpen: classes.react_autosuggest__input__open,
                inputFocused: classes.react_autosuggest__input__focused,
              }}
              suggestions={titlesuggestions}
              onSuggestionsFetchRequested={onTitlesuggestionsFetchRequested}
              onSuggestionsClearRequested={onTitlesuggestionsClearRequested}
              getSuggestionValue={getTitlesuggestionValue}
              renderSuggestion={renderTitlesuggestion}
              inputProps={titleInputProps}
            />
          </div>
        )}
        {autotype === 'name' && (
          <div id="auto-selector" className={classes.autosuggest_input}>
            <Autosuggest
              theme={{
                ...defaultTheme,
                input: classes.react_autosuggest__input,
                inputOpen: classes.react_autosuggest__input__open,
                inputFocused: classes.react_autosuggest__input__focused,
              }}
              suggestions={namesuggestions}
              onSuggestionsFetchRequested={onNamesuggestionsFetchRequested}
              onSuggestionsClearRequested={onNamesuggestionsClearRequested}
              getSuggestionValue={getNamesuggestionValue}
              renderSuggestion={renderNamesuggestion}
              inputProps={nameInputProps}
            />
          </div>
        )}
        <FormControl
          variant="outlined"
          size="small"
          className={classes.select_input}
        >
          <Select
            id="titletype-select-mui"
            multiple
            value={selectedTitleType}
            onChange={(e) => onSelectedTitleType(e.target.value)}
            input={<OutlinedInput>Movie Type</OutlinedInput>}
            renderValue={(selected) => selected.join(', ')}
          >
            {movieTypeOptions()}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          className={classes.select_input}
        >
          <Select
            id="genres-select-mui"
            multiple
            value={selectedGenres}
            onChange={(e) => onSelectedGenre(e.target.value)}
            input={<OutlinedInput>Genre</OutlinedInput>}
            renderValue={(selected) => selected.join(', ')}
          >
            {genreTypeOptions()}
          </Select>
        </FormControl>
        <div id="year-selector" className={classes.slider_input}>
          <Typography id="yr-slider">Years</Typography>
          <Slider
            value={years}
            min={basicStartYear}
            max={currentYear}
            onChange={onSelectYear}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            getAriaValueText={valuetext}
          />
        </div>
        <div id="votes-selector" className={classes.imdb_slider}>
          <Typography id="votes-slider">Votes ({votes})</Typography>
          <Slider
            aria-labelledby="discrete-slider-custom"
            getAriaValueText={valuetext}
            defaultValue={50000}
            min={0}
            max={2000000}
            step={200000}
            track="inverted"
            valueLabelDisplay="off"
            onChange={onChangeVotes}
          />
        </div>
        <div id="adult-selector" className={classes.select_input}>
          <Typography id="adult-toggle">Adult</Typography>
          <Toggle
            id="adult-status"
            defaultChecked={adult}
            onChange={(e) => onSelectAdult(e.target.checked)}
          />
        </div>
      </Grid>
      <Grid container item xs={10}>
        <Grid item xs={12}>
          {imdbdata !== null && imdbdata.length > 0 && (
            <DataGrid
              rows={imdbdata}
              columns={autotype === 'title' ? titleColumns : nameColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              //sortModel={sortModel}
              //onSortModelChange={onClickSort}
              disableSelectionOnClick
            />
          )}
          {imdbdata === '' && (
            <Typography id="nodata-search">No Data</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Search
